.news-sec2{
    padding: 50px;
    max-width: 1400px;
}
.news-sec2 .container-fluid  {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
}

.news-sec2 .list-holder {
  position: relative;
}

.news-sec2 .bg-holder{
    background: #ffffff;
    padding: 20px;
    box-shadow: 10px 10px 15px #f1f1f1;
}

.news-sec2 .bg-holder:hover{
    background: #ffffff;
    padding: 20px;
    box-shadow: 10px 10px 25px #cccccc;
    transition: 1s;
}

.news-sec2 .wrap-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #eee;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  max-width: 80px;
  -ms-flex-preferred-size: 80px;
  flex-basis: 80px;
  height: 80px;
  overflow: hidden;
  text-shadow: #dc818a 1px 1px, #dc818a 2px 2px, #dc818a 3px 3px,
    #dc818a 4px 4px, #dc818a 5px 5px, #dc818a 6px 6px, #dc818a 7px 7px,
    #dc818a 8px 8px, #dc818a 9px 9px, #dc818a 10px 10px, #dc818a 11px 11px,
    #dc818a 12px 12px, #dc818a 13px 13px, #dc818a 14px 14px, #dc818a 15px 15px,
    #dc818a 16px 16px, #dc818a 17px 17px, #dc818a 18px 18px, #dc818a 19px 19px,
    #dc818a 20px 20px, #dc818a 21px 21px, #dc818a 22px 22px, #dc818a 23px 23px,
    #dc818a 24px 24px, #dc818a 25px 25px, #dc818a 26px 26px, #dc818a 27px 27px,
    #dc818a 28px 28px, #dc818a 29px 29px, #dc818a 30px 30px, #dc818a 31px 31px,
    #dc818a 32px 32px, #dc818a 33px 33px, #dc818a 34px 34px, #dc818a 35px 35px,
    #dc818a 36px 36px, #dc818a 37px 37px, #dc818a 38px 38px, #dc818a 39px 39px,
    #dc818a 40px 40px, #dc818a 41px 41px, #dc818a 42px 42px, #dc818a 43px 43px,
    #dc818a 44px 44px, #dc818a 45px 45px, #dc818a 46px 46px, #dc818a 47px 47px,
    #dc818a 48px 48px, #dc818a 49px 49px, #dc818a 50px 50px, #dc818a 51px 51px,
    #dc818a 52px 52px, #dc818a 53px 53px, #dc818a 54px 54px, #dc818a 55px 55px,
    #dc818a 56px 56px, #dc818a 57px 57px, #dc818a 58px 58px, #dc818a 59px 59px,
    #dc818a 60px 60px, #dc818a 61px 61px, #dc818a 62px 62px, #dc818a 63px 63px,
    #dc818a 64px 64px, #dc818a 65px 65px, #dc818a 66px 66px, #dc818a 67px 67px,
    #dc818a 68px 68px, #dc818a 69px 69px, #dc818a 70px 70px, #dc818a 71px 71px,
    #dc818a 72px 72px, #dc818a 73px 73px, #dc818a 74px 74px, #dc818a 75px 75px,
    #dc818a 76px 76px, #dc818a 77px 77px, #dc818a 78px 78px, #dc818a 79px 79px,
    #dc818a 80px 80px, #dc818a 81px 81px, #dc818a 82px 82px, #dc818a 83px 83px,
    #dc818a 84px 84px, #dc818a 85px 85px, #dc818a 86px 86px;
}

.news-sec2 .text {
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  color: #dc3545;
  text-align: center;
}

.news-sec2 .wrap-img:after {
  content: "";
  font-size: 0;
  line-height: 0;
  display: block;
  min-height: 50px;
}

.news-sec2 .wrap-content {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 14px;
  padding-left: 15px;
}

.news-sec2 .wrap-content .title {
  -webkit-transition: color 0.3s linear;
  transition: color 0.3s linear;
  color: #27262c;
  line-height: 1;
}

.news-sec2 .news {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  text-decoration: none;
  color: #27262c;
  padding: 15px 0;
}

.news-sec2 .news:hover .title {
  color: #dc3545;
}

.news-sec2 .heading {
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  color: #75737f;
  line-height: 1;
  border-radius: 5px 5px 0 0;
  text-transform: uppercase;
  margin: 0 0 12px;
}

.news-sec2 .btn-link {
  color: #dc3545;
  font-weight: 500;
  font-size: 14px;
}

.news-sec2 .title {
  display: block;
  text-transform: uppercase;
  letter-spacing: 1.18px;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  margin: 0 0 5px;
  color: #75737f;
}

.news-sec2 .list-holder {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media (min-width: 992px) {
  .news-sec2 .container-fluid    {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .news-sec2 .list-holder:before {
    display: block;
  }

  .news-sec2{
    padding: 33px 35px 10px;
  }
}
