.nav-tabs-custom{
    justify-content: center;
    background-color: #e9ecef;
    border-radius: 21px;
    margin-top: 20px;
}

.nav-tabs-custom a{
    cursor: pointer;
}

.nav-tabs-custom a.active{
    border-bottom: 2px solid #c9152a;
}