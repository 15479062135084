@keyframes animate {
    0% {
        color: #f8de01;
        transform: translateY(0);
        margin-left: 0;
    }
    25% {
        color: #f8de01;
        transform: translateY(-15px);
        margin-left: 10px;
    }
    100% {
        color: #ff6e00;
        transform: translateY(0);
        margin-left: 0;
    }
}