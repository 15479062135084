.modal img {
  max-width: 100% !important;
  height: auto !important;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px;
  }
}

.popup-description > *:last-child {
  margin-bottom: 0 !important;
}

.modal-footer {
  border-top: none;
}

.modal-close-btn {
  position: absolute;
  right: -35px;
  top: -9px;
  color: #fff;
  font-size: 35px;
  width: 35px;
  height: 35px;
  text-shadow: none;
  outline: none !important;
  opacity: 1;
}

.modal-close-btn:hover {
  color: #fff;
}

.popup-item + .popup-item {
  padding-top: 10px;
}

.modal-content {
  background-color: transparent;
  border: none;
}

.modal-body {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.modal-body + .modal-body {
  margin-top: 15px;
}

@media (max-width: 992px) {
  .modal-close-btn {
    right: 9px !important;
    top: 9px !important;
    z-index: 10 !important;
    line-height: 11px !important;
    background-color: #c00 !important;
    opacity: 1 !important;
  }

  .modal-close-btn span {
    display: inline-block;
    padding: 0 0 7px;
  }
}
