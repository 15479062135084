.fixed-image-holder{
    width: 105px;
    height: 105px;
}

.max-content{
    max-width: max-content;
}

.list-page-item+.list-page-item{
    border-top: 1px solid #eee;
    padding-top: 15px;
    margin-top: 15px;
}

.list-page-item h4{
    padding-right: 90px;
    position: relative;
}

.list-page-item h4 a{
    color: #212529;
    text-decoration: none;
}

.list-page-item h4 a:hover{
    color: #272792;
}

.list-page-item .date{
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    line-height: 32px;
}